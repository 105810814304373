<template>
  <div class="form-wrapper">
    <h3 class="font-weight-bolder mb-1">
      {{ $t(ASSET_TABLE_TITLE) }}
    </h3>
    <b-table-simple
      v-if="assetItem.id"
      responsive
      class="asset-item-table"
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="{key,label, thStyle} in assetTableColumns"
            :key="key"
            :style="thStyle"
          >
            {{ label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <!--          <b-th>-->
          <!--            <button-dashed-->
          <!--              class="asset-item-table-audit-btn"-->
          <!--              @click="pushToAudits(assetItem)"-->
          <!--            >-->
          <!--              <feather-icon icon="LHandsIcon" />-->
          <!--            </button-dashed>-->
          <!--          </b-th>-->
          <b-th>{{ getValueFromGivenObjectByKey(assetItem, 'warehouse_location.warehouse.label', '—') }}</b-th>
          <b-th>
            <div
              class="d-flex align-items-center"
              style="gap: 8px"
            >
              <state-of-asset
                :data="assetItem"
                :with-label="false"
              />
              <span>
                {{ getValueFromGivenObjectByKey(assetItem, 'id', '—') }}
              </span>
            </div>
          </b-th>
          <b-th>{{ getValueFromGivenObjectByKey(assetItem, 'damaged.describe_the_issue', '—') }}</b-th>
          <!--          <b-th>-->
          <!--            <b-form-input-->
          <!--              v-model="assetItem.amount"-->
          <!--              class="custom-number-input"-->
          <!--              @input="countInputHandler($event)"-->
          <!--            />-->
          <!--          </b-th>-->
          <b-th>{{ getValueFromGivenObjectByKey(assetItem, 'damaged.issue_type', '—') }}</b-th>
          <b-th>{{ 'TBD' }}</b-th>
          <b-th>{{ getCreatorFullName(assetItem) }}</b-th>
          <!--          <b-th>{{ getValueFromGivenObjectByKey(assetItem, 'stage', '—') }}</b-th>-->
          <b-th>
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="LCreditCardIconFill"
                size="18"
                :class="getValueFromGivenObjectByKey(assetItem, 'damaged.is_bill', null) ? 'green' : 'dark'"
              />
            </div>
          </b-th>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <asset-skeleton v-else />
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTh, BTr, BFormInput,
} from 'bootstrap-vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import config from '../../config'
import AssetSkeleton from './AssetSkeleton.vue'

export default {
  name: 'Asset',
  components: {
    StateOfAsset,
    BFormInput,
    BTr,
    BTh,
    BTbody,
    BThead,
    BTableSimple,
    ButtonDashed,
    AssetSkeleton,
  },
  computed: {
    assetItem() {
      return this.$store.state[this.MODULE_NAME].assetItem
    },
  },
  methods: {
    countInputHandler($event) {
      if (Number($event) >= 10000) {
        $event.preventDefault()
      }
    },
    pushToAudits(data) {
      const { id, product_id } = data
      const route = this.$router.resolve({
        name: 'home-inventory-item-audit',
        params: { id: product_id, inv_id: id },
      })
      window.open(route.href)
    },
    getCreatorFullName(data) {
      const creator = data.damaged?.by
      if (creator) {
        return `${creator.firstname} ${creator.lastname}`
      }
      return '—'
    },
  },
  setup() {
    const { ASSET_TABLE_TITLE, MODULE_NAME, assetTableColumns } = config()

    return {
      MODULE_NAME,
      assetTableColumns,
      ASSET_TABLE_TITLE,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
<style lang="scss">
.asset-item-table {
  tbody th {
    padding: 7px 0;
    border: 1px solid #dde3e7;
    font-weight: normal;

    &:first-child {
      border-radius: 5px 0 0 5px;
      padding-left: 12px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
      padding-right: 12px;
    }

    &:not(:last-child) {
      border-right: none;
    }

    &:not(:first-child) {
      border-left: none;
    }
  }

  &-audit-btn {
      padding: 5px !important;

      svg {
        fill: #ADADAD;
      }
    }

  .green path {
      fill: #09735F;
  }

  .dark path {
    fill: #000000 !important;
  }

  .red path{
    fill: #cb2027;
  }
}
</style>
