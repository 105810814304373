<template>
  <b-table-simple
    responsive
    class="asset-item-table"
  >
    <b-thead>
      <b-tr>
        <b-th
          v-for="{key,label, thStyle} in assetTableColumns"
          :key="key"
          :style="thStyle"
        >
          {{ label }}
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-th>
          <b-skeleton
            width="18px"
            height="18px"
          />
        </b-th>
        <b-th><b-skeleton width="80%" /></b-th>
        <b-th>
          <div
            class="d-flex"
            style="gap: 8px"
          >
            <b-skeleton
              width="16px"
              height="16px"
            />
            <b-skeleton
              width="50%"
            />
          </div>
        </b-th>
        <b-th><b-skeleton width="80%" /></b-th>
        <b-th><b-skeleton width="80%" /></b-th>
        <b-th><b-skeleton width="80%" /></b-th>
        <b-th><b-skeleton width="80%" /></b-th>
        <b-th><b-skeleton width="80%" /></b-th>
        <b-th class="d-flex justify-content-center">
          <b-skeleton
            width="22px"
            height="18px"
          />
        </b-th>
      </b-tr>

    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTh, BTr, BSkeleton,
} from 'bootstrap-vue'
import config from '../create/received-quote/config'

export default {
  name: 'AssetSkeleton',
  components: {
    BTh,
    BTr,
    BThead,
    BTbody,
    BSkeleton,
    BTableSimple,
  },
  setup() {
    const { assetTableColumns } = config()

    return {
      assetTableColumns,
    }
  },
}
</script>
<style lang="scss">
.asset-item-table {
  .b-skeleton {
    //margin-bottom: 0 !important;
    margin: 3.8px 0;
  }
}
</style>
