import { L_CURRENCY_MASK_INPUT, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  function formatterNegativesValue(val) {
    return val.replace(/[^0-9]/g, '')
  }

  const dynamicTableCols = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '55%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'price',
      label: 'Price',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'discount',
      label: 'Discount',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'taxes',
      label: 'Taxes',
      thStyle: { width: '8%', textAlign: 'center' },
    },
    {
      key: 'totals',
      label: 'Sub-total',
      thStyle: { width: '13%', textAlign: 'center' },
    },
  ]

  const model = {
    name: '',
    unit: '1',
    price: null,
    discount: null,
    tax: null,
    totals: null,
  }

  const modelFields = {
    name: {
      type: TEXT_INPUT,
      label: '',
    },
    unit: {
      type: TEXT_INPUT,
      label: '',
    },
    discount: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
      formatter: formatterNegativesValue,
    },
    price: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
      formatter: formatterNegativesValue,
    },
    taxes: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
      formatter: formatterNegativesValue,
    },
    totals: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
      formatter: formatterNegativesValue,
    },
  }

  return {
    model,
    modelFields,
    dynamicTableCols,
    formatterNegativesValue,
  }
}
