<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'BaseFormBottom',
  components: { BButton },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: this.path })
    },
  },
}
</script>

<template>
  <portal to="body-footer">
    <div
      class="d-flex mt-2 pb-1 justify-content-between"
    >
      <div>
        <b-button
          v-if="true"
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="navigateTo"
        >
          {{ $t('Back to List') }}
        </b-button>
        <b-button
          v-else
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <slot />
    </div>
  </portal>
</template>
