import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  // Constants
  const SERVICE_ORDER_INFORMATION_TITLE = 'Service Order Information'

  const orderInformationObjectForRender = [
    {
      key: 'customer.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'payment_term.label',
      label: 'Payment Terms',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')} 
      `,
      label: 'Billing Contact',
    },
    {
      key: ({ attachments }) => attachments?.map(({ name }) => name).join(', '),
      label: 'Attachments',
    },
    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Dispatch Warehouse',
    },
    {
      key: 'billing_contact.phone_one',
      label: 'Billing Phone',
    },
    {
      key: 'return_warehouse.label',
      label: 'Return Warehouse',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'customer.default_address.street', '—')} 
      ${getValueFromGivenObjectByKey(data, 'customer.default_address.city', '')} 
      ${getValueFromGivenObjectByKey(data, 'customer.default_address.zip', '')} 
      `,
      label: 'Billing Address',
    },
    {
      key: 'dispatch_method.name',
      label: 'Dispatch Method',
    },
    {
      key: '',
      label: '',
    },
    {
      key: 'return_method.name',
      label: 'Return Method',
    },

  ]

  // Store Mutations
  const storeMutationsList = [
    {
      mutation: 'SET_ORDERS_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_FORM',
      payload: {
        attachments: [],
      },
    },
    {
      mutation: 'SET_INSURANCE_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_DISPATCH_AND_RETURN_FORM',
      payload: {},
    },
    {
      mutation: 'SET_EVENT_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_RENTAL_TABLE',
      payload: {
        products: [],
      },
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: {
        otherCharges: [],
      },
    },
    {
      mutation: 'SET_TOTALS_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
    {
      mutation: 'SET_IS_SETTLED_CUSTOMER',
      payload: false,
    },
    {
      mutation: 'SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_EVENT_INFORMATION_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_DISPATCH_AND_RETURN_ALL_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
  ]

  // Form Buttons Title

  const FORM_BUTTON_VOID = {
    title: 'VOID',
    action: 'void',
  }

  const FORM_BUTTON_SELL_ASSET = {
    title: 'Sell Asset',
    action: 'sell_asset',
  }

  const FORM_BUTTON_BILL_SERVICE = {
    title: 'Bill Service',
    action: 'bill_service',
  }

  const FORM_BUTTON_PRINT = {
    title: 'Print',
    action: 'print',
  }

  const FORM_BUTTON_ACCEPT = {
    title: 'Accept',
    action: 'accept',
  }

  const formBottomActions = [
    {
      variant: 'danger',
      style: {
        minWidth: '125px',
        height: '40px',
      },
      action: FORM_BUTTON_VOID.action,
      title: FORM_BUTTON_VOID.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'danger',
      style: {
        minWidth: '140px',
        height: '40px',
      },
      action: FORM_BUTTON_SELL_ASSET.action,
      title: FORM_BUTTON_SELL_ASSET.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'secondary',
      style: {
        minWidth: '150px',
        height: '40px',
      },
      action: FORM_BUTTON_BILL_SERVICE.action,
      title: FORM_BUTTON_BILL_SERVICE.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'secondary',
      style: {
        minWidth: '130px',
        height: '40px',
      },
      action: FORM_BUTTON_PRINT.action,
      title: FORM_BUTTON_PRINT.title,
      icon: 'LPrintIcon',
      formSubmitAction: 'submit',
    },
    {
      variant: 'success',
      style: {
        minWidth: '140px',
        height: '40px',
      },
      action: FORM_BUTTON_ACCEPT.action,
      title: FORM_BUTTON_ACCEPT.title,
      icon: 'LSignatureIcon',
      formSubmitAction: 'submit',
    },
  ]

  const formBottomActionsInvoice = [
    {
      variant: 'secondary',
      style: {
        minWidth: '130px',
        height: '40px',
      },
      action: FORM_BUTTON_PRINT.action,
      title: FORM_BUTTON_PRINT.title,
      icon: 'LPrintIcon',
      formSubmitAction: 'submit',
    },
    {
      variant: 'success',
      style: {
        minWidth: '150px',
        height: '40px',
      },
      action: FORM_BUTTON_BILL_SERVICE.action,
      title: FORM_BUTTON_BILL_SERVICE.title,
      formSubmitAction: 'submit',
    },
  ]

  const notesFields = {
    internal_order_reference: {
      type: TEXT_INPUT,
      label: 'Internal Order Reference Notes',
    },
    internal_notes: {
      type: TEXT_INPUT,
      label: 'Service Order Request Notes (visible to the supplier)',
    },
    received_comments: {
      type: TEXT_INPUT,
      label: 'Received Comments',
    },
  }

  const SERVICES_PERFORMED_TABLE_TITLE = 'Services Performed'
  const OTHER_CHARGES_ITEMS_TABLE_TITLE = 'Other Charges/Items'
  const RECEIVED_ATTACHMENTS = 'Received Attachments'
  const TOTALS_TITLE = 'Totals'
  const NOTES_TITLE = 'Notes'
  const TOTALS_DISCOUNTS_TITLE = 'Discounts'
  const TOTALS_TAXES_TITLE = 'Taxes'
  const TOTALS_TOTAL_TITLE = 'Total'

  return {
    notesFields,
    TOTALS_TITLE,
    NOTES_TITLE,
    FORM_BUTTON_VOID,
    FORM_BUTTON_PRINT,
    formBottomActions,
    FORM_BUTTON_ACCEPT,
    TOTALS_TOTAL_TITLE,
    storeMutationsList,
    TOTALS_TAXES_TITLE,
    RECEIVED_ATTACHMENTS,
    TOTALS_DISCOUNTS_TITLE,
    FORM_BUTTON_SELL_ASSET,
    formBottomActionsInvoice,
    FORM_BUTTON_BILL_SERVICE,
    SERVICES_PERFORMED_TABLE_TITLE,
    OTHER_CHARGES_ITEMS_TABLE_TITLE,
    orderInformationObjectForRender,
    SERVICE_ORDER_INFORMATION_TITLE,
  }
}
