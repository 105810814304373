<template>
  <div ref="other-charges-ref">
    <div
      class="bg-white p-1"
      style="border-radius: 5px"
    >
      <h3 class="font-weight-bolder text-body">
        {{ $t(title) }}
      </h3>
      <b-table-simple
        :class="`other-charges-items-table other-charges-items ${
          tableItemsLength === 1 ? 'other-charges-items-table--item-one' : ''
        }`"
      >
        <b-thead>
          <b-tr>
            <b-td
              v-for="{ key, label, thStyle } in dynamicTableCols"
              :key="key"
              :style="thStyle"
              class="font-weight-bolder"
            >
              {{ label }}
            </b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <tr
            v-for="({ unit }, index) in dynamicTableListArr"
            :key="index"
          >
            <td
              class="p-0"
              style="padding-left: 12px !important"
            >
              <feather-icon
                icon="LTrashIcon"
                size="30"
                style="padding: 4px; fill: #646464; background-color: #f3f3f3"
                class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
                @click="remove(index)"
              />
            </td>
            <td class="text-left pl-0">
              <component
                :is="modelFields['name'].type"
                v-model="dynamicTableListArr[index]['name']"
                class="other-charges-items__name"
                v-bind="getProps('name', modelFields)"
              />
            </td>
            <td>
              <b-form-input
                v-model="dynamicTableListArr[index]['unit']"
                class="other-charges-items__name other-charges-items__name--text-center other-charges-items__name--b-form-input"
                type="number"
                :formatter="formatterNegativesValue"
                @input="getTotalItems"
                @change="changeDetect(index)"
              />
            </td>
            <td>
              <component
                :is="modelFields['price'].type"
                v-model="dynamicTableListArr[index]['price']"
                class="other-charges-items__name other-charges-items__name--text-center"
                v-bind="getProps('price', modelFields)"
                :formatter="formatterNegativesValue"
                @input="getTotalSubtotal"
                @change="changeDetect(index)"
              />
            </td>
            <td>
              <component
                :is="modelFields['discount'].type"
                v-model="dynamicTableListArr[index]['discount']"
                type="number"
                class="other-charges-items__name other-charges-items__name--text-center"
                v-bind="getProps('discount', modelFields)"
                :formatter="formatterNegativesValue"
                @input="getTotalDiscount"
                @change="changeDetect(index)"
              />
            </td>
            <td>
              {{
                formatNumberToCurrencyView(getChargeTax(index))
              }}
            </td>
            <td>
              <span>
                {{
                  formatNumberToCurrencyView(getChargeSubtotal(index))
                }}
              </span>
            </td>
          </tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex">
        <b-form-input
          ref="charge-input-name"
          v-model="chargeName"
          class="border-right-0"
          style="width: 85%"
          :class="`charge-input ${tableItemsLength ? 'charge-input-name' : ''}`"
          :placeholder="$t('Type...')"
          @keydown.tab.prevent="createChargeItem($event)"
          @keydown.enter.prevent="createChargeItem($event)"
        />
        <b-form-input
          ref="charge-input-unit"
          v-model="chargeUnit"
          class="border-left-0 border-right-0"
          style="width: 10%"
          :class="`charge-input charge-unit ${
            tableItemsLength ? 'charge-input-name' : ''
          }`"
          type="number"
          :formatter="formatterNegativesValue"
          @keydown.tab.prevent="createChargeItem($event)"
          @keydown.enter.prevent="createChargeItem($event)"
        />
        <b-form-input
          ref="charge-input-price"
          v-model="chargePrice"
          class="border-left-0 border-right-0"
          style="
             {
              border-radius: 0 !important;
            }
          "
          :class="`charge-input charge-unit ${
            tableItemsLength ? 'charge-input-name' : ''
          }`"
          type="number"
          :formatter="formatterNegativesValue"
          @keydown.tab.prevent="createChargeItem($event)"
          @keydown.enter.prevent="createChargeItem($event)"
        />
        <b-form-input
          ref="charge-input-disc"
          v-model="chargeDisc"
          class="border-left-0"
          :class="`charge-input charge-unit ${
            tableItemsLength ? 'charge-input-name' : ''
          }`"
          type="number"
          :formatter="formatterNegativesValue"
          @keydown.tab.prevent="createChargeItem($event)"
          @keydown.enter.prevent="createChargeItem($event)"
        />
      </div>
      <b-table-simple class="other-charges-items-table">
        <b-thead>
          <b-tr>
            <b-td
              v-for="{ key, thStyle } in dynamicTableCols"
              :key="key"
              :style="thStyle"
              style="height: 0; padding: 0"
            />
          </b-tr>
        </b-thead>
        <b-tfoot>
          <tr>
            <td />
            <td
              class="text-right"
              style="width: 55%"
            >
              <b>{{ $t("TOTAL") }}</b>
            </td>
            <td style="padding-left: 25px">
              <b>{{ sumTotalAmountOfGivenArrayByProperty(
                dynamicTableListArr,
                'unit',
              ) }}</b>
            </td>
            <td>
              <b>${{ totalSubtotal ? totalSubtotal.toFixed(2) : 0 }}</b>
            </td>
            <td>
              <b>${{ totalDiscount ? totalDiscount.toFixed(2) : 0 }}</b>
            </td>
            <td>
              <b><span>${{ getTotalSumOfTaxes }}</span></b>
            </td>
            <td>
              <b>${{ totalSumOfItems.toFixed(2) }}</b>
            </td>
          </tr>
        </b-tfoot>

      </b-table-simple>
      <p class="text-danger">
        {{ chargeError }}
      </p>
    </div>
  </div>
</template>
<script>
import {
  sumTotalAmountOfGivenArrayByProperty,
  getProps,
  getValueFromGivenObjectByKey,
  formatNumberToCurrencyView,
} from '@core/utils/utils'
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTd,
  BFormInput,
} from 'bootstrap-vue'
import LCurrencyMaskInput from '@/views/components/DynamicForm/components/LCurrencyMaskInput.vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'OtherCharges',
  components: {
    BTr,
    BTd,
    BThead,
    BTbody,
    BTfoot,
    BFormInput,
    BTableSimple,
    LCurrencyMaskInput,
  },
  props: {
    chargeError: {
      type: String,
      required: false,
      default: () => '',
    },
    listName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chargeName: ' ',
      chargeUnit: null,
      chargePrice: null,
      chargeDisc: null,
      totalItems: 0,
      totalDiscount: 0,
      totalSubtotal: 0,
      totalSumOfItems: 0,
    }
  },
  computed: {
    getChargeTax() {
      return index => {
        const item = this.dynamicTableListArr[index]
        const baseAmount = (+item.price * +item.unit) - +item.discount
        return (baseAmount * (+this.taxRule / 100)).toFixed(2)
      }
    },
    getChargeSubtotal() {
      return index => {
        const item = this.dynamicTableListArr[index]
        const baseAmount = (+item.price * +item.unit) - +item.discount
        const taxAmount = (baseAmount * (+this.taxRule / 100))
        return (baseAmount + taxAmount)
      }
    },
    taxRule() {
      return this.$store.state[this.MODULE_NAME].order.customer?.customer_detail?.tax_rule?.value ?? 0
    },
    dynamicTableListArr: {
      get() {
        return this.dynamicTableList
      },
      set(newVal) {
        this.dynamicTableList = newVal
      },
    },
    dynamicTableList: {
      get() {
        return this.$store.state[this.MODULE_NAME][this.listName] // Dynamic Table List
      },
      set(newVal) {
        this.$store.state[this.MODULE_NAME][this.listName] = newVal
      },
    },
    tableItemsLength() {
      return this.dynamicTableListArr.length
    },
    getTotalSumOfTaxes() {
      return this.sumTotalAmountOfGivenArrayByProperty(
        this.dynamicTableListArr.map(charge => ({
          tax: ((charge.price * charge.unit) - charge.discount) * (+this.taxRule / 100),
        })),
        'tax',
      ).toFixed(2)
    },
  },
  watch: {
    tableItemsLength() {
      this.getTotalItems()
      this.getTotalDiscount()
      this.getTotalSubtotal()
      this.getTotalSumOfItems()
    },
  },
  methods: {
    formatNumberToCurrencyView,
    getValueFromGivenObjectByKey,
    remove(index) {
      this.dynamicTableListArr = this.dynamicTableListArr.filter(
        (_, idx) => idx !== index,
      )
    },
    createChargeItem() {
      if (this.chargeName && !this.chargeUnit) {
        this.$refs['charge-input-unit'].focus()
      } else if (this.chargeName && this.chargeUnit && !this.chargePrice) {
        this.$refs['charge-input-price'].focus()
      }

      if (this.chargeName && this.chargeUnit && this.chargePrice) {
        this.dynamicTableList.push({
          ...this.model,
          name: this.chargeName,
          unit: this.chargeUnit,
          price: this.chargePrice,
          discount: this.chargeDisc ?? 0,
          tax: this.calculateTax(this.chargePrice, this.chargeUnit, this.chargeDisc ?? 0),
        })
        this.chargeName = ''
        this.chargeUnit = null
        this.chargePrice = null
        this.getTotalItems()
        this.getTotalSumOfItems()
        this.$refs['charge-input-name'].focus()
      }
    },
    calculateTax(price, unit, discount) {
      const baseAmount = (price * unit) - discount
      return (baseAmount * (this.taxRule / 100)).toFixed(2)
    },
    calculateSubtotal(price, unit, discount, tax) {
      return ((+price * +unit) - (+discount || 0) + +tax)
    },
    changeDetect(index) {
      this.dynamicTableListArr[index].tax = +this.calculateTax(this.dynamicTableListArr[index].price, this.dynamicTableListArr[index].unit, this.dynamicTableListArr[index].discount)
      this.dynamicTableListArr[index].subtotal = this.calculateSubtotal(this.dynamicTableListArr[index].price, this.dynamicTableListArr[index].unit, this.dynamicTableListArr[index].discount, this.dynamicTableListArr[index].tax)
      this.dynamicTableListArr[index].tax = (this.dynamicTableListArr[index].tax).toFixed()
    },
    getTotalItems() {
      this.totalItems = this.sumTotalAmountOfGivenArrayByProperty(
        this.dynamicTableListArr,
        'unit',
      )

      this.getTotalSumOfItems()
      this.$emit('sumAction')
    },
    getTotalDiscount() {
      this.totalDiscount = this.sumTotalAmountOfGivenArrayByProperty(
        this.dynamicTableListArr,
        'discount',
      )
      this.getTotalSumOfItems()
      this.$emit('sumAction')
    },
    getTotalSubtotal() {
      this.totalSubtotal = this.sumTotalAmountOfGivenArrayByProperty(
        this.dynamicTableListArr,
        'price',
      )
      this.getTotalSumOfItems()
      this.$emit('sumAction')
    },
    getTotalSumOfItems() {
      this.totalSumOfItems = this.sumTotalAmountOfGivenArrayByProperty(
        this.dynamicTableListArr.map(charge => ({
          // eslint-disable-next-line no-mixed-operators
          itemTotalSum:
              ((charge.price
                  * charge.unit
              ) - charge.discount) + ((charge.price * charge.unit) - charge.discount) * (+this.taxRule / 100),
        })),
        'itemTotalSum',
      )
    },

  },
  setup() {
    const {
      model, modelFields, dynamicTableCols, formatterNegativesValue,
    } = config()

    const { MODULE_NAME } = mainConfig()

    return {
      dynamicTableCols,
      getProps,
      model,
      MODULE_NAME,
      modelFields,
      sumTotalAmountOfGivenArrayByProperty,
      formatterNegativesValue,
    }
  },
}
</script>
<style lang="scss">
.other-charges-items-table {
  margin-bottom: 0;

  thead td,
  tfoot td {
    border: 0 !important;
  }

  td {
    padding: 5px 12px;
  }

  tbody td,
  tfoot td {
    text-align: center;
  }

  tbody tr {
    height: 36px;
  }

  tbody td {
    padding: 0 12px;
  }

  tbody tr:last-child td:first-child {
    border-radius: 0 !important;
  }

  tbody tr:last-child td:last-child {
    border-radius: 0 !important;
  }

  &--item-one {
    tbody tr:last-child td:first-child {
      border-radius: 5px 0 0 0 !important;
    }

    tbody tr:last-child td:last-child {
      border-radius: 0 5px 0 0 !important;
    }
  }

  .other-charges-items__name {
    margin-bottom: 0;

    & input {
      width: 100%;
      padding: 0 2px;
      background: transparent;
      height: 30px;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #dde3e7;
        box-shadow: none;
      }
    }

    &--text-center {
      input {
        width: 100%;
        text-align: center;
      }
    }

    &--b-form-input {
      width: 100%;
      text-align: center;
      padding: 0 2px;
      background: transparent;
      height: 30px;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #dde3e7;
        box-shadow: none;
      }
    }
  }

  .other-charges-items__mask {
    margin-bottom: 0;
    width: 100%;

    & .form-control {
      text-align: center;
      padding: 0 4px;
      height: 32px !important;
      background: transparent !important;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #dde3e7;
      }
    }
  }
}

.charge-input {
  height: 36px;
  width: 20%;
  &:focus {
    border: 1px solid #dde3e7;
  }
  &.form-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.charge-unit.form-control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid #dde3e7;
  }
}

.charge-input-name {
  border-radius: 0 0 0 5px;
}

.charge-input-number {
  border-radius: 0 0 5px 0;
}

.charge-input-name,
.charge-input-number {
  border-top: none;

  &:focus {
    border-top: none;
  }
}

.direct-req {
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #dde3e7 !important;
}
</style>
